<template>
  <div class="about__content d-flex flex-column">
    <page-header
      :background="require('@/assets/about/img.png')"
      title="Sobre Nós"
      subtitle="A maior liga da Paraíba"
    />

    <v-container class="py-16">
      <v-row class="mb-12" justify="center" align="center">
        <v-col cols="6" sm="5" md="4" xl="3">
          <v-img :src="require('@/assets/games/valorant/agents/2.png')" />
        </v-col>

        <v-col cols="10" md="6" xl="5">
          <div class="text-h6 text-xl-h5 font-weight-regular">
            <p class="mb-0">
              Os jogos eletrônicos ajudam o aluno a refletir, tomar decisões,
              fazer descobertas, desenvolver sua criatividade, aprendendo a
              agir, estimulando a curiosidade, a iniciativa e a autoconfiança,
              ao mesmo tempo em que é proporcionado o desenvolvimento da
              linguagem, do pensamento e da concentração.
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="10" md="6" xl="5">
          <div class="text-h6 text-xl-h5 font-weight-regular">
            <p>
              Participarão dos jogos promovidos pela Liga os estudantes
              regularmente matriculados em uma Instituição de Ensino no Estado
              da Paraíba.
            </p>

            <p class="mb-0">
              As modalidades para participação são: FIFA22, Clash Royale, Free
              Fire, CS:GO e Valorant
            </p>
          </div>
        </v-col>

        <v-col cols="6" sm="5" md="4" xl="3">
          <v-img :src="require('@/assets/games/valorant/agents/1.png')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
